















import Vue from "vue";
import { Prop } from "vue/types/options";
import { mapGetters } from "vuex";

import { ShopGroup } from "../../utils/utils";
import ShopSeller from "./ShopSeller.vue";

export default Vue.extend({
  name: "ShopSellerList",
  components: { ShopSeller },
  props: {
    groupList: Array as Prop<Array<ShopGroup>>,
  },
  computed: {
    ...mapGetters("root", ["lang"]),
    ...mapGetters("user", ["userId"]),
    filteredGroupList() {
      return this.groupList.filter((c: any) =>
        [11863, 11861].includes(c.platform)
      );
    },
  },
  data() {
    return {
      activeSeller: 0,
    };
  },
  created() {
    console.log("filteredGroupList", this.filteredGroupList);
  },
});
