





























































import Vue from "vue";
import { Prop } from "vue/types/options";

import { getCountryName, logEventFA, ShopProduct } from "../../utils/utils";
import ShopProductPopup from "./ShopProductPopup.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  name: "ShopProducts",
  components: { ShopProductPopup, Swiper, SwiperSlide },
  props: {
    title: String,
    productList: Array as Prop<Array<ShopProduct>>,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 16,
        centeredSlides: true,
        loop: true,
        loopedSlides: 2,
        loopAdditionalSlides: 2,
        // lazy: {
        //   loadPrevNext: true,
        // },
        // preloadImages: false,
      },
      isDetailOpened: false,
      currentProduct: 0,
    };
  },
  mounted() {
    this.$watch(
      "sharedShopId",
      (newId: number) => {
        if (!newId) return false;
        this.currentProduct = newId;
        this.isDetailOpened = true;
      },
      { deep: true, immediate: true }
    );
  },
  computed: {
    ...mapGetters("user", ["userId"]),
    ...mapGetters("root", ["lang", "sharedShopId"]),
    detailedProduct(): ShopProduct | undefined {
      return this.productList.find((p) => p.id === +this.currentProduct);
    },
    filteredProductList(): ShopProduct[] {
      return this.productList.filter((p: any) =>
        [11863, 11861].includes(p.platform)
      );
    },
  },
  methods: {
    ...mapMutations("root", ["setSharedShopId"]),
    productClicked(index: number, realIndex: number) {
      this.currentProduct = this.filteredProductList[realIndex].id;
      this.isDetailOpened = true;

      if (this.detailedProduct) {
        logEventFA("open_product", {
          price: this.detailedProduct.price,
          currency: "USD",
          group_id: getCountryName(this.detailedProduct.seller.country),
          //@ts-ignore
          customer_id: this.userId,
          item_list: this.detailedProduct.seller.name,
          item_variant: this.detailedProduct.name[this.lang],
        });
      }
    },
    closeDetail() {
      this.isDetailOpened = false;
      if (this.sharedShopId) this.setSharedShopId(null);
    },
  },
});
