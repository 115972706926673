











import Vue from "vue";
import { Prop } from "vue/types/options";
import { mapGetters } from "vuex";

import { createRequest } from "@/services/api-connector";
import eventsRegister from "@/services/events-register";
import { getTransformedShops, ShopGroup, ShopProduct } from "@/utils/utils";

import ShopRates from "./ShopRates.vue";
import ShopProducts from "./ShopProducts.vue";
import ShopSellerList from "./ShopSellerList.vue";

export default Vue.extend({
  name: "ShopSuggest",
  components: { ShopRates, ShopProducts, ShopSellerList },
  props: {
    routeparam: Object as Prop<any>,
  },
  data() {
    return {
      eventsRegister,
      isLoading: true,
      groupList: [] as ShopGroup[],
      productList: [] as ShopProduct[],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const reqData = {
        ClassList: [
          { Class_id: 23960, TypeLoad: 1, needLinkObject: 1 },
          { Class_id: 23970, TypeLoad: 1, needLinkObject: 1 },
          { Class_id: 23330, TypeLoad: 3, needLinkObject: 1 },
        ],
        Filter: [
          { Class_id: 23960, Property_id: -4, Operator_id: "=", Value: -5001 },
          { Class_id: 23970, Property_id: -4, Operator_id: "=", Value: -5001 },
          { Class_id: 23330, Property_id: -4, Operator_id: "=", Value: -5001 },
        ],
      };
      if (this.userCountry === 284) {
        reqData.Filter.push({
          Class_id: 23970,
          Property_id: 201,
          Operator_id: "=",
          Value: 287,
        });
      }
      createRequest("objList", reqData, (res: any) => {
        this.isLoading = false;
        const { groupList, productList } = getTransformedShops(res);
        console.log("groupList", groupList);
        this.groupList = groupList;
        this.productList = productList;
      });
    },
  },
  computed: {
    ...mapGetters("user", ["userCountry"]),
  },
});
