<template>
  <div class="currency-select" :class="{ isOpened }">
    <div class="currency-select__item">
      <div class="currency-select__country" @click="openSelect">
        <img :src="require(`@/assets/img/${selectedItem.image}`)" />
        <span>{{ selectedItem.name }}</span>
        <img class="currency-select__arr" src="@/assets/img/arr-down.svg" />
      </div>
      <div class="currency-select__input">
        <input
          ref="currencyInput"
          :value="currencyValue"
          v-mask="priceTokens"
          placeholder="0"
          @input="onValueChange"
          @focus="changeCurretPosition"
          @select="changeCurretPosition"
          type="tel"
        />
        <span>{{ selectedItem.symbol }}</span>
      </div>
    </div>
    <div class="currency-select__list">
      <div
        v-for="item in list"
        :key="item.name"
        class="currency-select__option"
        @click="onSelect(item)"
      >
        <img :src="require(`@/assets/img/${item.image}`)" />
        <span>{{ item.name }}, {{ item.label }}</span>
      </div>
    </div>
    <div class="currency-select__overlay" @click="closeSelect"></div>
  </div>
</template>

<script>
import { moveCaretToEnd } from "@/utils/utils";
import { mask } from "vue-the-mask";

export default {
  name: "ConvertCurrencySelect",
  directives: { mask },
  props: {
    direction: {
      type: String,
      default: "",
    },
    selectedItem: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => ({}),
    },
    currentRate: {
      type: Number,
      default: 0,
    },
    currencyValue: {
      type: String,
      default: "",
    },
    convertedValue: {
      type: String,
    },
  },
  data() {
    return {
      isOpened: false,
      priceTokens: {
        mask: "PPPPPPPPPPPPPPPP",
        tokens: {
          P: { pattern: /^[0-9]*\.?[0-9]*$/ },
        },
      },
    };
  },
  methods: {
    onValueChange(e) {
      this.$emit("update:currencyValue", e.target.value);
      let convertedResult = 0;
      if (this.direction === "FROM") {
        convertedResult = this.$options.filters.numberRound(
          this.currentRate * e.target.value
        );
      }
      if (this.direction === "TO") {
        convertedResult = this.$options.filters.numberRound(
          e.target.value / this.currentRate
        );
      }
      this.$emit("update:convertedValue", `${convertedResult}`);
    },
    openSelect() {
      this.isOpened = true;
    },
    closeSelect() {
      this.isOpened = false;
    },
    onSelect(item) {
      this.closeSelect();
      this.$emit("update:selectedValue", item.name);
    },
    changeCurretPosition() {
      moveCaretToEnd(this.$refs["currencyInput"]);
    },
  },
};
</script>

<style lang="scss">
.currency-select {
  position: relative;

  &:first-child {
    z-index: 10;
  }

  &.isOpened {
    z-index: 33;
  }

  &__list {
    position: absolute;
    top: 60px;
    width: 100%;
    background: #333333;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding-top: 25px;
    padding-bottom: 10px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);

    .isOpened & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: 0.3s;
    }
  }

  &__option {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 16px;
    padding-right: 16px;
    color: #bfbfbf;
    display: flex;
    align-items: center;

    span {
      display: block;
      line-height: 1;
      padding-left: 16px;
    }

    img {
      display: block;
    }
  }

  &__overlay {
    background: rgba(#282828, 0.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: -1;

    .isOpened & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__item {
    background: #333333;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-height: 74px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;
    position: relative;
    z-index: 1;
    border: 1px solid transparent;
    transition: 0.3s;

    .isOpened & {
      border: 1px solid #765d2e;
    }
  }

  &__country {
    display: flex;
    align-items: center;
    line-height: 1;
    width: 50%;
    img {
      display: block;
    }

    span {
      font-family: "Helvetica Neue Medium";
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      min-width: 70px;
      text-align: center;
      padding-top: 4px;
    }
  }

  &__input {
    min-width: 150px;
    width: 50%;
    font-family: "Helvetica Neue Medium";
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #efb443;

    input {
      width: 100%;
      border: none;
      outline: none;
      background: none;
      text-align: right;
      color: #efb443;
      font-family: "Helvetica Neue Medium";
      font-size: 16px;

      &::placeholder {
        color: #efb443;
      }
    }

    span {
      min-width: 16px;
      text-align: right;
      display: block;
    }
  }
  &__arr {
    position: relative;
    left: -16px;
    top: 1px;
  }
}
</style>
