
























































































































import Vue from "vue";
import { Prop } from "vue/types/options";
import { leftArrowSVG } from "@/services/svg";

import {
  getCountryName,
  logEventFA,
  getOrderRequestData,
  openExternal,
  ShopProduct,
  generateShopDynamicLink,
  copyToClipboard,
  convertImgToDataURLviaCanvas,
} from "@/utils/utils";
import EasyCounter from "@/components/common/EasyCounter.vue";
import EasyInput from "@/components/common/EasyInput.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import AddBuyoutSuccess from "@/views/AddBuyout/AddBuyoutSuccess.vue";
import TransitionExpand from "@/components/TransitionExpand.vue";
import { createRequest } from "@/services/api-connector";
import eventsRegister from "@/services/events-register";
import { mapMutations } from "vuex";

export default Vue.extend({
  name: "ShopProductPopup",
  components: {
    EasyCounter,
    EasyInput,
    AddBuyoutSuccess,
    Swiper,
    SwiperSlide,
    TransitionExpand,
  },
  props: {
    product: Object as Prop<ShopProduct>,
    lang: String,
    customerId: Number,
    onClose: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      leftArrowSVG,
      swiperOptions: {
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
        },
      },
      count: 1,
      color: "",
      size: "",
      model: "",
      isAdditionalOpened: false,
      isLoading: false,
      isCreated: false,
      imageBase64: "",
    };
  },
  created() {
    convertImgToDataURLviaCanvas(
      this.product.images[0],
      (data) => (this.imageBase64 = data)
    );
  },
  computed: {
    shareMsg(): string {
      return `${this.$t("shopsShareTitle")}\n\n${
        this.product.name[this.lang]
      }\n${this.$t("shopsPrice")}: $${this.product.price}\n\n`;
    },
  },
  methods: {
    ...mapMutations("root", ["setSharedShopId"]),
    openLink() {
      openExternal(this.product.link);
    },
    onSubmit() {
      this.isLoading = true;

      const data = getOrderRequestData({
        name: this.product.name[this.lang],
        source: this.product.link,
        country: this.product.seller.country,
        count: this.count,
        color: this.color,
        size: this.size,
        model: this.model,
        customerId: this.customerId,
        isLink: true,
        type: 11599,
        mainImg: this.product.imgList[0],
      });

      createRequest(
        "objEdit",
        data,
        () => {
          this.$root.$emit(eventsRegister.updateParcels);
          this.isLoading = false;
          this.isCreated = true;
          //   this.sendSuccessEvents();
        },
        () => (this.isLoading = false)
      );

      logEventFA("buy_product", {
        price: this.product.price,
        currency: "USD",
        group_id: getCountryName(this.product.seller.country),
        customer_id: this.customerId,
        item_list: this.product.seller.name,
        item_variant: this.product.name[this.lang],
      });
    },
    onFinish() {
      //@ts-ignore
      this.onClose();
      this.setSharedShopId(null);
      this.$router.push("/");
    },

    onShareLink() {
      const sharePlugin = window["plugins"] && window["plugins"].socialsharing;

      const onSuccess = () => {
        if (!sharePlugin)
          this.$root.$emit(eventsRegister.addMessage, {
            type: "success",
            message: this.$t("copied"),
            autoClose: true,
          });
      };

      generateShopDynamicLink(
        {
          sharedShopId: this.product.id,
          imageLink: "https://i.ibb.co/k1PVxDh/677.png",
        },
        (url) => {
          if (!sharePlugin) {
            copyToClipboard(`${this.shareMsg}${url}`, onSuccess);
            return false;
          }
          (sharePlugin as any).shareWithOptions(
            {
              message: `${this.shareMsg}${url}`,
              chooserTitle: this.$t("refferalShareTitle"),
            },
            onSuccess
          );
        }
      );
    },
  },
});
