<template>
  <div class="shops-rates">
    <div v-if="userIsFromUSA" class="shops-rates__item">
      $ ≈
      <span>{{ this.currentRates.CNY }}¥</span>
    </div>
    <div v-if="!userIsFromUSA" class="shops-rates__item">
      ¥ ≈
      <span
        >{{ (this.currentRates.UAH / this.currentRates.CNY).toFixed(1) }}₴</span
      >
    </div>
    <!-- <div class="shops-rates__item">
      ₺ ≈
      <span>{{(this.currentRates.UAH/this.currentRates.TRY).toFixed(1)}}₴</span>
    </div> -->

    <div class="shops-rates__convert" @click="$router.push('converter')">
      <img src="@/assets/img/Converter.svg" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ShopRates",
  computed: {
    ...mapGetters("user", ["currencyRates", "user"]),
    currentRates() {
      const rates = {};
      this.currencyRates.forEach((r) => (rates[r.pr_242] = r.pr210));
      return rates;
    },
    userIsFromUSA() {
      return this.user.pr201 === 284;
    },
  },
});
</script>

<style lang="scss">
.shops-rates {
  display: flex;
  background: #282828;
  height: 36px;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 2;

  &__convert {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #333333;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: absolute;
    right: 16px;
    bottom: 0px;
  }
}
.shops-rates__item {
  font-size: 12px;
  color: #bfbfbf;
  font-family: "Helvetica Neue Medium";
  border-left: 1px solid #696969;
  padding-left: 16px;
  margin-left: 16px;

  &:first-child {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }
}
.shops-rates__item span {
  color: #91c258;
}
</style>
