<template>
  <div class="converter-page">
    <div class="converter-header">
      <div class="converter-header__back" @click="onBack">
        <img src="@/assets/img/arr-l-w.svg" />
      </div>
      <div class="converter-header__title">{{ $t("converterSB") }}</div>
    </div>
    <div class="converter-page__content">
      <convert-currency-select
        :selectedValue.sync="currencyFrom"
        :list="currencyList"
        :selectedItem="currencyFromItem"
        :currencyValue.sync="currencyFromValue"
        :convertedValue.sync="currencyToValue"
        :currentRate="currentRate"
        direction="FROM"
      />
      <div class="convereter-menu">
        <div class="convereter-menu__swipe" @click="swapCurrency">
          <img src="@/assets/img/Exchange.svg" />
        </div>
        <div class="convereter-menu__current">
          <img src="@/assets/img/tranding-up.svg" />
          <span
            >1 {{ currencyFromItem.symbol }} ≈ {{ currentRate }}
            {{ currencyToItem.symbol }}</span
          >
        </div>
      </div>
      <convert-currency-select
        :selectedValue.sync="currencyTo"
        :list="currencyList"
        :selectedItem="currencyToItem"
        :currencyValue.sync="currencyToValue"
        :convertedValue.sync="currencyFromValue"
        :currentRate="currentRate"
        direction="TO"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ConvertCurrencySelect from "./ConvertCurrencySelect.vue";

const currencyList = [
  {
    name: "USD",
    image: "flagUSD.svg",
    symbol: "$",
  },
  {
    name: "CNY",
    image: "flagCNY.svg",
    symbol: "¥",
  },
  // {
  //   name: "TRY",
  //   image: "flagTRY.svg",
  //   symbol: "₺",
  // },
  {
    name: "UAH",
    image: "flagUAH.svg",
    symbol: "₴",
  },
];
export default {
  components: { ConvertCurrencySelect },
  name: "ConvertCurrency",
  data() {
    return {
      currencyFrom: "USD",
      currencyTo: "UAH",
      currencyFromValue: "",
      currencyToValue: "",
    };
  },
  methods: {
    onBack() {
      this.$router.push("/");
    },
    swapCurrency() {
      const { currencyFrom, currencyTo } = this;
      this.currencyFrom = currencyTo;
      this.currencyTo = currencyFrom;
      this.updateResults();
    },
    updateResults() {
      this.currencyToValue = `${this.$options.filters.numberRound(
        this.currentRate * this.currencyFromValue
      )}`;
    },
  },
  created() {
    if (this.userIsFromUSA) {
      this.currencyTo = "CNY";
    }
  },
  computed: {
    ...mapGetters("user", ["currencyRates", "userCountry"]),
    currencyPreparedList() {
      return currencyList.map((currency) => ({
        ...currency,
        label: this.$t(`${currency.name.toLowerCase()}Title`),
      }));
    },
    currencyList() {
      return this.currencyPreparedList.filter(
        (c) =>
          ![this.currencyFromItem.name, this.currencyToItem.name].includes(
            c.name
          )
      );
    },
    userIsFromUSA() {
      return [284, 301, 308].includes(this.userCountry);
    },
    currencyFromItem() {
      return this.currencyPreparedList.find(
        (c) => c.name === this.currencyFrom
      );
    },
    currencyToItem() {
      return this.currencyPreparedList.find((c) => c.name === this.currencyTo);
    },
    existedRates() {
      const rates = {};
      this.currencyRates.forEach((r) => (rates[r.pr_242] = r.pr210));
      return rates;
    },
    currentRate() {
      if (this.currencyFrom === "USD") {
        return this.$options.filters.numberRound(
          this.existedRates[this.currencyTo],
          5
        );
      }
      if (this.currencyTo === "USD") {
        return this.$options.filters.numberRound(
          1 / this.existedRates[this.currencyFrom],
          5
        );
      }
      return this.$options.filters.numberRound(
        this.existedRates[this.currencyTo] /
          this.existedRates[this.currencyFrom],
        5
      );
    },
  },
  watch: {
    currencyFrom() {
      this.updateResults();
    },
    currencyTo() {
      this.updateResults();
    },
  },
};
</script>

<style lang="scss">
.converter-page {
  display: flex;
  flex-direction: column;
  background: #282828;
  margin: -84px -16px -98px;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  &:after {
    left: 0;
    right: 0;
    width: 100%;
    height: 400px;
    top: -300px;
    background: #282828;
    display: block;
    position: absolute;
    content: "";
    z-index: -1;
  }

  &__content {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 100px;
  }

  .ios & {
    min-height: calc(100vh - env(safe-area-inset-top));
  }
}

.converter-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 58px;
  padding-top: 16px;
  &__back {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__actions {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    flex: 1 auto;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-family: "Helvetica Neue Medium";
  }
}

.convereter-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  img {
    display: block;
  }

  &__swipe {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__current {
    display: flex;
    align-self: center;
    color: #696969;
    font-size: 14px;
    font-family: "Helvetica Neue Medium";
    img {
      margin-right: 6px;
      margin-top: -2px;
    }
  }
}
</style>
