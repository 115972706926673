





































import Vue from "vue";
import { Prop } from "vue/types/options";

import { getCountryName, logEventFA, openExternal, ShopSeller } from "../../utils/utils";
import TransitionExpand from "@/components/TransitionExpand.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default Vue.extend({
  name: "ShopSeller",
  components: { TransitionExpand, Swiper, SwiperSlide },
  props: {
    title: String,
    list: Array as Prop<Array<ShopSeller>>,
    lang: String,
    id: Number,
    activeSeller: Number,
    customerId: Number,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 16,
        centeredSlides: true,
        slidesPerView: 1,
        initialSlide: 1,
        // loopedSlides: 2,
        // loop: true,
        // lazy: {
        //   loadPrevNext: true,
        // },
        // preloadImages: false,
      },
      currentIndex: 0,
    };
  },
  computed: {
    isActive(): boolean {
      return this.id === this.activeSeller;
    },
    currentSeller(): ShopSeller {
      return this.list[this.currentIndex];
    },
    slider(): any {
      //@ts-ignore
      return this.$refs[this.id].$swiper;
    }
  },
  methods: {
    openLink(link: string) {
      openExternal(link);
      logEventFA('view_shop', {
        group_id: getCountryName(this.currentSeller.country), 
        customer_id: this.customerId, 
        item_list: this.title, 
        item_variant: this.currentSeller.name,
      })
    },
    sliderClicked(index: number) {
      if(this.currentIndex !== index) {
        this.currentIndex = index;
        this.slider.slideTo(index, 500, false);
      } else if (this.isActive) {
        this.openLink(this.currentSeller.link);
        return false;
      }
      this.$emit("update:activeSeller", this.id);
    },
    slideChange() {
      logEventFA('view_category', {
        group_id: getCountryName(this.currentSeller.country), 
        customer_id: this.customerId, 
        item_list: this.title, 
        item_variant: this.currentSeller.name,
      })
      if(this.currentIndex !== this.slider.realIndex) {
        this.currentIndex = this.slider.realIndex;
      }
      if (this.isActive)  return false;
      this.$emit("update:activeSeller", this.id);
    },
  },
});
